import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

import {
  AddToWhitelistResponse,
  ApiProducts,
  Area,
  BusinessLine,
  Country,
  DeleteFromWhitelistResponse,
  GetWhitelistResponse,
  GetWhitelistResult,
  OrgUnit
} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

type WhitelistOption = 'email' | 'sms' | 'push'

const RESPONSE_OK = 200
const RESPONSE_CREATED = 201
const apigeeEnvironment = process.env.REACT_APP_API_PRODUCT_ENV

const {
  AreasSchemaID,
  AreaSchemaID,
  CountriesSchemaID,
  CountrySchemaID,
  BusinessLinesSchemaID,
  OrgUnitsSchemaID
} = SchemaIDs

// API defined endpoints
export const getAreas =
  (api: AxiosInstance) =>
  (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse<Area[]>> => {
    const config = {params, schemaId: AreasSchemaID, ...axiosConfig}
    return api.get('/configurations/areas', config)
  }

export const getArea =
  (api: AxiosInstance) =>
  (
    areaCode: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Area>> => {
    const config = {params, schemaId: AreaSchemaID, ...axiosConfig}
    return api.get(`/configurations/areas/${areaCode}`, config)
  }

export const getCountries =
  (api: AxiosInstance) =>
  (
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Country[]>> => {
    const config = {params, schemaId: CountriesSchemaID, ...axiosConfig}
    return api.get('/configurations/countries', config)
  }

export const getCountry =
  (api: AxiosInstance) =>
  (
    countryCode: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<Country>> => {
    const config = {params, schemaId: CountrySchemaID, ...axiosConfig}
    return api.get(`/configurations/countries/${countryCode}`, config)
  }

export const getBusinessLines =
  (api: AxiosInstance) =>
  (
    country?: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<BusinessLine[]>> => {
    const config = {params: {...params, country}, schemaId: BusinessLinesSchemaID, ...axiosConfig}
    return api.get('/configurations/businessLines', config)
  }

export const getApiProducts =
  (api: AxiosInstance) =>
  (
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<ApiProducts>> => {
    const config = {params: {...params}, ...axiosConfig}
    return api.get(`/apigee-service/${apigeeEnvironment}/apiproducts`, config)
  }

export const getOrgUnits =
  (api: AxiosInstance) =>
  (
    country?: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<OrgUnit[]>> => {
    const config = {params: {...params, country}, schemaId: OrgUnitsSchemaID, ...axiosConfig}
    return api.get('/configurations/orgUnits', config)
  }

export const getCommentCategories = (api: AxiosInstance) => (): Promise<AxiosResponse<string[]>> =>
  api.get('/configurations/commentCategories')

export const getWhitelist =
  (api: AxiosInstance) =>
  async (type: WhitelistOption): Promise<GetWhitelistResponse> => {
    const response = await api.get<GetWhitelistResult>(`/configurations/whitelists/${type}`, {
      validateStatus: () => true
    })

    if (response.status !== RESPONSE_OK) {
      return {type: 'error', error: {errorCode: response.status, message: 'fail'}}
    }

    return {type: 'value', value: response.data}
  }

export const addToWhitelist =
  (api: AxiosInstance) =>
  async ({
    type,
    entry
  }: {
    type: WhitelistOption
    entry: string[]
  }): Promise<AddToWhitelistResponse> => {
    const response = await api.post(`configurations/whitelists/${type}`, entry, {
      validateStatus: () => true
    })

    if (response.status !== RESPONSE_CREATED) {
      return {
        type: 'error',
        error: {errorCode: response.status, message: `error adding ${entry} to whitelist`}
      }
    }

    return {type: 'value', value: response.data}
  }

export const deleteFromWhitelist =
  (api: AxiosInstance) =>
  async ({
    type,
    entry
  }: {
    type: WhitelistOption
    entry: string
  }): Promise<DeleteFromWhitelistResponse> => {
    const response = await api.delete(
      `/configurations/whitelists/${type}?entry=${encodeURIComponent(entry)}`,
      {
        validateStatus: () => true
      }
    )

    if (response.status !== RESPONSE_OK) {
      return {
        type: 'error',
        error: {errorCode: response.status, message: `error deleting ${entry} from whitelist`}
      }
    }

    return {type: 'value', value: response.data}
  }
